// import logo from "./logo.svg";
// import "./App.css";
import { Button } from "./components/Button";
import { Container } from "./components/Container";
import { CTAOne } from "./components/cta/CtaOne";
import { FAQOne } from "./components/faq/FAQOne";
import { FeaturesOne } from "./components/features/FeaturesOne";
import { FeaturesOneWithImage } from "./components/features/FeaturesOneWithImage";
import { FeaturesTwoWithImage } from "./components/features/FeaturesTwoWithImage";
import { Header } from "./components/header/Header";
import { HeaderTwo } from "./components/header/HeaderTwo";
import { Hero } from "./components/hero/Hero";
import HeroThree from "./components/hero/HeroThree";
import HeroTwo from "./components/hero/HeroTwo";
import { PricingOne } from "./components/pricing/PricingOne";
import { TestimonialOne } from "./components/testimonials/TestimonialOne";
import { TestimonialTwo } from "./components/testimonials/TestimonialTwo";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./pages/Main";
import About from "./pages/About";
import FAQ from "./pages/FAQ";
import Stock from "./pages/Stock";
import RealEstate from "./pages/RealEstate";
import Crypto from "./pages/Crypto";
import Forex from "./pages/Forex";
import Why from "./pages/Why";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import DefaultPage from "./pages/404";
import Legal from "./pages/Legal";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RealEstate />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/faq",
      element: <FAQ />,
    },
    {
      path: "/stocks",
      element: <Stock />,
    },
    {
      path: "/crypto",
      element: <Crypto />,
    },
    {
      path: "/forex",
      element: <Forex />,
    },
    {
      path: "/realestate",
      element: <RealEstate />,
    },
    {
      path: "/why-us",
      element: <Why />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/legal",
      element: <Legal />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "*",
      element: <DefaultPage />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

{
  /* <div style={{ backgroundColor: "#0c0f19" }}>
<Header />
</div>
<HeaderTwo />
<HeroThree />
<div style={{ backgroundColor: "#0c0f19" }}>
<Hero />
</div>
<FeaturesTwoWithImage />
<TestimonialOne />
<FAQOne />
<TestimonialTwo />
<PricingOne />
<CTAOne />
<FeaturesOne />
<FeaturesOneWithImage />
<TestimonialTwo /> */
}

export default App;
